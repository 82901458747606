 jQuery(function($) {
   var yourNavigation = $(".navigation__holder");
   stickyDiv = "sticky";
   yourHeader = $('.navigation__holder').height();

   $(window).scroll(function() {
     if ($(this).scrollTop() > 0) {
       yourNavigation.addClass(stickyDiv);

       $('#sticky-anchor').height($('.navigation__holder').height());
     } else {
       yourNavigation.removeClass(stickyDiv);
       $('#sticky-anchor').height('0px');
     }
   });

   $('.nav-icon').on('click', function(){
      $('.mobile-nav__holder').toggleClass('is-open');
      $('#navigation').toggleClass('is-open');
      $('.nav-icon').toggleClass('is-open');
   });
 });
