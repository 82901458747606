(function($) {
    /*global site*/
    var busy = false;

    /**
     * Add the function exists() to jQuery objects.
     *
     * @returns {boolean}
     */
    $.fn.exists = function() {
        return this.length !== 0;
    };

    /**
     * Scroll to given element. Otherwise scroll to top of the page.
     */
    function scrollTo($obj, extra, speed, callback) {
        var $rootElem      = (typeof $obj === 'undefined') ? null : $obj,
            extraTop       = (typeof extra === 'undefined') ? 0 : extra,
            animationSpeed = (typeof speed === 'undefined') ? 250 : speed,
            doCallback     = (typeof callback === 'undefined') ? false : callback,
            target         = 0;

        // Set target to given object
        if ($rootElem !== null && $rootElem.length) {
            target = parseInt(($rootElem.offset().top), 10) + extraTop;
        }

        // Define root element
        if ($('html').scrollTop()) {
            $rootElem = $('html'); // FF and IE
        } else if ($('body').scrollTop()) {
            $rootElem = $('body'); // Chrome, Safari and Opera
        } else {
            $rootElem = $('html, body'); // Fallback
        }

        // Scroll with or without callback
        if (doCallback !== false && typeof(doCallback) === 'function') {
            $rootElem.stop().animate({scrollTop: target}, animationSpeed).promise().done(doCallback);
        } else {
            $rootElem.stop().animate({scrollTop: target}, animationSpeed);
        }
    }

    /**
     * PHP's number_format in JavaScript.
     *
     * @param number
     * @param decimals
     * @param decPoint
     * @param thousandsSep
     *
     * @returns {string}
     */
    function number_format(number, decimals, decPoint, thousandsSep) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');

        var n    = !isFinite(+number) ? 0 : +number;
        var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
        var sep  = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
        var dec  = (typeof decPoint === 'undefined') ? '.' : decPoint;
        var s    = '';

        var toFixedFix = function(n, prec) {
            var k = Math.pow(10, prec);

            return '' + (Math.round(n * k) / k).toFixed(prec);
        };

        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');

        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }

        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }

        return s.join(dec);
    }


})(jQuery); // Fully reference jQuery after this point.
