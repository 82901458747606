/* jshint ignore:start */
jQuery(document).ready(function ($) {
    /**
     * ONCLICK run loadNewsItems function
     */
    var totalNewsItems = 3,
        offsetNews = 0,
        totalposts = 0;

    if ($('.js-cards').length) {
        loadNewsItems(totalNewsItems, offsetNews);
    }

    $('.js-filter').on('click', function (e) {
        var _ = $(this);
        e.preventDefault();

        offsetNews = 0;
        $('.js-filter').removeClass('__active');
        _.addClass('__active');

        loadNewsItems(totalNewsItems, offsetNews);
    });

    $('.js-showmore').on('click', function (e) {
        e.preventDefault();
        offsetNews = offsetNews + totalNewsItems;

        addMorePosts(totalNewsItems, offsetNews);
    });
});

/**
 * GET POST BY CATEGORY in AJAX
 */
function loadNewsItems(totalNewsItems, offsetNews) {
    var active_category = jQuery('.js-filters').find('.__active').data('category');

    jQuery.ajax({
        url: "/wp-admin/admin-ajax.php?action=get_posts_ajax",
        data: {
            category: active_category,
            offset: offsetNews,
            postperpage: totalNewsItems
        },
        type: 'POST',
        success:
            function (result) {
                var data = JSON.parse(result);
                jQuery('.js-cards').html(data);

                var currentitems = jQuery('.js-cards .card').length;
                var maxitems = jQuery('.js-cards .card').data('total');

                if (currentitems >= maxitems) {
                    jQuery('.js-showmore').hide();
                } else {
                    jQuery('.js-showmore').show();
                }
            }
    })
}

/**
 * GET POST BY CATEGORY in AJAX
 */
function addMorePosts(totalNewsItems, offsetNews) {
    var active_category = jQuery('.js-filters').find('.__active').data('category');

    jQuery.ajax({
        url: "/wp-admin/admin-ajax.php?action=get_posts_ajax",
        data: {
            category: active_category,
            offset: offsetNews,
            postperpage: totalNewsItems,
            action:
                "get_posts_ajax",
        },
        type: 'POST',
        success:
            function (result) {
                var data = JSON.parse(result);
                jQuery('.js-cards').append(data);

                var currentitems = jQuery('.js-cards .card').length;
                var maxitems = jQuery('.js-cards .card').data('total');

                if (currentitems >= maxitems) {
                    jQuery('.js-showmore').hide();
                } else {
                    jQuery('.js-showmore').show();
                }
            }
    })
}


