jQuery(document).ready(function ($) {

    if ($('.content-carousel').length) {
        $('.content-carousel').slick({
            dots: false,
            arrows: true,
            appendArrows: '.content-carousel-buttons',
            nextArrow: '<button type="button" class="slick-next"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="white"/></svg></button>',
            prevArrow: '<button type="button" class="slick-prev"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="white"/></svg></button>',
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            centerMode: true
        });
    }

        if ($('.uitdaging-slider').length) {
            $('.uitdaging-slider').slick({
                dots: false,
                arrows: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                centerMode: true
            });
        }

    if($(window).width() <= 750) {
        if ($('.mobile-slider').length) {
            $('.mobile-slider').slick({
                dots: false,
                arrows: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                centerMode: true
            });
        }
    }

    if ($('.sliderprojectenimage').length) {
        $('.sliderprojectenimage').slick({
            dots: true,
            arrows: true,
            appendDots: '.sliderprojectenimage__dots',
            appendArrows: '.sliderprojectenimage__arrows',
            nextArrow: '<button type="button" class="slick-next"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="white"/></svg></button>',
            prevArrow: '<button type="button" class="slick-prev"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="white"/></svg></button>',
            infinite: true,
            speed: 678,
            slidesToShow: 1,
            asNavFor: '.sliderprojectencontent',
            draggable: false
        });
    }

    if ($('.sliderprojectencontent').length || $('.sliderheadercontent').length) {
        $('.sliderprojectencontent, .sliderheadercontent').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 678,
            slidesToShow: 1,
            draggable: false
        });
    }

    if ($('.sliderheader').length) {
        $('.sliderheader').slick({
            dots: true,
            arrows: true,
            appendDots: '.sliderheader__dots',
            appendArrows: '.sliderheader__arrows',
            nextArrow: '<button type="button" class="slick-next"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="#0176c9"/></svg></button>',
            prevArrow: '<button type="button" class="slick-prev"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="#0176c9"/></svg></button>',
            infinite: true,
            speed: 678,
            slidesToShow: 1,
            asNavFor: '.sliderheadercontent',
            draggable: false
        });
    }

    if ($('.sliderbig').length) {
        $('.sliderbig').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 678,
            slidesToShow: 1,
            draggable: false
        });
    }

    if ($('.sliderbigcontent').length) {
        $('.sliderbigcontent').slick({
            dots: true,
            arrows: true,
            infinite: true,
            appendArrows: '.sliderbigcontent__arrows',
            nextArrow: '<button type="button" class="slick-next"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="#0176c9"/></svg></button>',
            prevArrow: '<button type="button" class="slick-prev"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="#0176c9"/></svg></button>',
            speed: 678,
            asNavFor: '.sliderbig',
            slidesToShow: 1,
            draggable: false
        });
    }

    if ($('.cheaderslider').length) {
        $('.cheaderslider').slick({
            dots: true,
            arrows: true,
            appendDots: '.cheaderslider__dots',
            appendArrows: '.cheaderslider__arrows',
            infinite: true,
            nextArrow: '<button type="button" class="slick-next"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="#0176c9"/></svg></button>',
            prevArrow: '<button type="button" class="slick-prev"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="#0176c9"/></svg></button>',
            speed: 678,
            slidesToShow: 1,
            draggable: false
        });
    }

    if ($('.relatedcasesslider').length) {
        $('.relatedcasesslider').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 678,
            slidesToShow: 2,
            centerMode: true,
            centerPadding: '75px',
        });
    }


    if ($('.detailsslider').length) {
        $('.detailsslider').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 678,
            slidesToShow: 1,
            draggable: false
        });
    }

    if ($('.detailsthumbsslider').length) {
        $('.detailsthumbsslider').slick({
            dots: false,
            arrows: true,
            appendArrows: '.detailsthumbsarrows',
            nextArrow: '<button type="button" class="slick-next"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="#0176c9"/></svg></button>',
            prevArrow: '<button type="button" class="slick-prev"><svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.1949 0.823242L20.7366 2.28162L26.9239 8.46893L0.03125 8.46893L0.03125 10.5315L26.9237 10.5315L20.7366 16.7186L22.1949 18.177L30.8719 9.50011L22.1949 0.823242Z" fill="#0176c9"/></svg></button>',
            infinite: true,
            speed: 678,
            slidesToShow: 4,
            draggable: false
        });

        $('.detailsthumbsslider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $('.detailsslider').slick('slickGoTo', nextSlide);
        });

        $('.detailsthumbsslider img').on('click', function (e) {
            var goTo = $(this).data('slide');
            $('.detailsslider').slick('slickGoTo', goTo - 1);
            $('.detailsthumbsslider').slick('slickGoTo', goTo - 1);
        });
    }


    $('.js-diensten a').on('hover', function (e) {
        var _ = $(this),
            source = _.data('img'),
            container = _.closest('.js-container');

        container.find('.js-source').attr('src', source);
    });

    $('.js-more').on('click', function (e) {
        var _ = $(this),
            container = _.closest('.js-container'),
            childs = container.find('.js-child');

        e.preventDefault();

        childs.addClass('__show');
        childs.removeClass('__hidden');

        _.fadeOut();
    });

    $('.js-alles').on('click', function (e) {
        e.preventDefault();

        var _ = $(this),
            parent = _.closest('.js-parent'),
            klapper = parent.find('.js-hidden');

        klapper.slideToggle();
        parent.toggleClass('__open');
    });

    var filtersArray = [];

    $.each($('.js-parent'), function (index, value) {
        var _ = $(this),
            filterObject = {},
            category = _.data('parent'),
            categoryID = _.data('parentid');

        filterObject.id = categoryID;
        filterObject.name = category;
        filterObject.filter = [];

        filtersArray.push(filterObject);
    });

    var timer;
    var i = 0;
    var checkedFilters = [];

    function myTimer(sec, filters) {
        if (timer){
            clearInterval(timer);
            i = 0;
        }
        timer = setInterval(function(event) {
            if (i === sec) {
                jQuery.ajax({
                            url: "/wp-admin/admin-ajax.php?action=get_producten",
                    data: {
                        filters: filters
                    },
                    type: 'POST',
                    success:
                        function (result) {
                            $('.js-producten').html(JSON.parse(result));

                        },
                    complete:
                        function () {
                            var max = $('.js-producten').find('.card').data('max');

                            if(!max){
                                max = 0;
                            }

                            $('.js-gevonden x').text(max);
                        }
                })
            }
            i++;
        }, 1000);
    }

    var filters = [];

    $('.js-product-filter input').on('change', function (e) {
        var _ = $(this),
            parent = _.closest('.js-parent'),
            parentCategory = parent.data('parentid'),
            checkedFilters = [],
            big = _.closest('.js-big');

        big.find('input[type=checkbox]:checked').each(function () {
            var _ = $(this),
                value = _.data('category');

            checkedFilters.push(value);
        });


        // filtersArray.map(function (value) {
        //     if (value.id === parentCategory) {
        //         value.filter = checkedFilters;
        //     }
        // });


        myTimer(1, checkedFilters);
    });

});
